import React from 'react';
import './App.css';
// import logo from './logo.svg';
import coverImage from './assets/cover_image.jpeg';
import { PolicyRevivalIcon, PolicyAssignmentIcon, CustomerServiceIcon } from "./component/icons/Icons"

const App = () => {
  return (
    <div className="App">
      {/* <header>
        <div className="logo"> */}
      {/* <img src="/api/placeholder/50/50" alt="ReviveInsure Logo" /> */}
      {/* <img src={logo} alt="ReviveInsure Logo" width="50" height="50" />
          <h1>ReviveInsure Solutions</h1>
        </div>
        <p>Preserving Policies, Ensuring Futures</p>
      </header> */}
      <nav>
        <ul>
          <li><a href="#about">About Us</a></li>
          <li><a href="#challenge">The Challenge</a></li>
          <li><a href="#solution">Our Solution</a></li>
          <li><a href="#technology">Technology</a></li>
          <li><a href="#benefits">Benefits</a></li>
          <li><a href="#contact">Contact Us</a></li>
        </ul>
      </nav>
      <div className="cover-image" style={{ backgroundImage: `url(${coverImage})` }}>
        <div className="cover-overlay">
          {/* <h2>Bridging the Gap in Insurance</h2>
          <p>Revolutionizing policy continuity and customer retention</p> */}
        </div>
      </div>
      <main>
        <section id="about">
          <h2>About ReviveInsure</h2>
          <p>At ReviveInsure Solutions, we are committed to revolutionizing the insurance industry by addressing the critical challenges of policy discontinuation and lapses. With a proven track record of purchasing over ₹6 crore worth of policies with Max Life Insurance, we offer a risk-mitigated, technology-driven, and result-oriented solution to the challenges facing the insurance industry today.</p>
        </section>

        <section id="challenge">
          <h2>The Challenge</h2>
          <div className="card">
            {/* <img src="/api/placeholder/64/64" alt="Challenge Icon" /> */}
            <h3>High Policy Lapse Rates</h3>
            <p>Approximately half of all policies lapse before reaching maturity, causing significant losses for policyholders, insurance companies, and agents.</p>
          </div>
          <p>With an estimated ₹2 Lakh crore worth of policies lapsing/surrendered in India in FY22-23, it's a big market up for disruption.</p>
        </section>

        <section id="solution">
          <h2>Our Solution: A Two-Pronged Strategy</h2>
          <div className="card-container">
            <div className="card">
              {/* <img src="/api/placeholder/64/64" alt="Revival Icon" /> */}
              <PolicyRevivalIcon size={32} color="#0056b3" />
              <h3>1. Policy Revival</h3>
              <p>We interact with policyholders possessing lapsed policies, elucidating policy benefits and offering financial solutions to foster revival.</p>
            </div>
            <div className="card">
              {/* <img src="/api/placeholder/64/64" alt="Assignment Icon" /> */}
              <PolicyAssignmentIcon size={32} color="#0056b3" />
              <h3>2. Purchase/Assignment</h3>
              <p>We propose to acquire policies via assignment from clients who can't sustain them, ensuring they attain liquidity while preserving the insurance benefits.</p>
            </div>
          </div>
          <p>Our platform allows policyholders to sell their existing policies to a trust through a PTC (Pass Through Certificate), providing better compensation than surrender while retaining part of their death coverage.</p>
        </section>

        <section id="technology">
          <h2>Cutting-Edge Technology Solutions</h2>
          <div className="card-container">
            {[
              "Advanced Customer Service Training",
              "Vernacular Engagement",
              "Data-Driven Customer Interaction",
              "CRM Integration",
              "Electronic Document Signing",
              "Enhanced Policyholder Platform",
              "API Ecosystems"
            ].map((item, index) => (
              <div key={index} className="card">
                {/* <img src="/api/placeholder/64/64" alt={`${item} Icon`} /> */}
                <CustomerServiceIcon size={32} color="#0056b3" />
                <h3>{item}</h3>
              </div>
            ))}
          </div>
        </section>

        <section id="benefits">
          <h2>Benefits for All Stakeholders</h2>
          <div className="card-container">
            <div className="card">
              {/* <img src="/api/placeholder/64/64" alt="Insurance Companies Icon" /> */}
              <h3>For Insurance Companies</h3>
              <ul>
                <li>Tangible reduction in policy lapses</li>
                <li>Increased revenue</li>
                <li>Fortified customer relations</li>
              </ul>
            </div>
            <div className="card">
              {/* <img src="/api/placeholder/64/64" alt="Policyholders Icon" /> */}
              <h3>For Policyholders</h3>
              <ul>
                <li>Financial liquidity through policy assignment</li>
                <li>Guaranteed policy life cover benefits to the original holder</li>
                <li>Better compensation than through surrender</li>
              </ul>
            </div>
            <div className="card">
              {/* <img src="/api/placeholder/64/64" alt="Insurance Agents Icon" /> */}
              <h3>For Insurance Agents</h3>
              <ul>
                <li>Retention of existing commissions</li>
                <li>Additional earning opportunities</li>
              </ul>
            </div>
          </div>
        </section>

        <section id="contact">
          <h2>Contact Us</h2>
          <p>Interested in learning more about how ReviveInsure Solutions can help your insurance company or manage your policy? Get in touch with us today!</p>
          <a href="mailto:reviveinsure@gmail.com" className="cta-button">Email Us</a>
        </section>
      </main>
      <footer>
        <p>&copy; 2024 ReviveInsure Solutions. All rights reserved.</p>
        {/* <p>Email: info@reviveinsure.com | Phone: +91 XXXXXXXXXX</p> */}
      </footer>
    </div>
  );
};

export default App;